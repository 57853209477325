import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import Store from "../index";

// TODO: extend this into more generic functionality for pages. Right now it's pretty specific to help page

const endpoint = "pages";

interface PageSaveData {
    id: string;
    content: string;
}

@Module({
    dynamic: true,
    store: Store,
    name: "PagesModule",
    namespaced: true
})
export default class Pages extends VuexModule {
    content = "";
    error = false;

    get pageContent() {
        return this.content;
    }

    get hasError() {
        return this.error;
    }

    @Mutation
    public setContent(data: string) {
        this.content = data;
    }

    @Mutation
    public setError(data: boolean) {
        this.error = data;
    }

    @Action({ commit: "setContent" })
    getContent(pageId: string) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}/${pageId}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data.mainBody);
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        return resolve("");
                    } else {
                        return reject(error);
                    }
                });
        });
    }

    @Action({})
    saveContent(payload: PageSaveData) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(
                    `/${endpoint}/${payload.id}`,
                    {
                        title: "Help Page",
                        mainBody: payload.content
                    },
                    {
                        headers: {
                            Authorization: `bearer ${token}`
                        }
                    }
                )
                .then((response) => {
                    this.context.commit("setError", false);
                    return resolve("saved");
                })
                .catch((error) => {
                    this.context.commit("setError", true);
                    return reject(error);
                });
        });
    }
}
