













































































import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import Ads from "@/components/shared/Ads.vue";
import { VueEditor } from "vue2-editor";

import pagesVuexModule from "@/store/vuex-modules/pages";
const pagesStore = getModule(pagesVuexModule);

interface HeaderObject {
    id: string;
    text: string;
    children?: Array<HeaderObject>;
}

function getPreviousElementBySelector(elem: HTMLElement, selector: string) {
    // Get the next sibling element
    let sibling = elem.previousElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.previousElementSibling;
    }
}

@Component({
    components: {
        PageTitleHeader,
        VueEditor,
        Spinners,
        "fmi-ads": Ads
    }
})
export default class HelpPageView extends Vue {
    helpContent = "";
    headers: Array<HeaderObject> = [];
    parsedContent = "";
    showNotification = false;
    isLoading = true;
    editorSettings = [
        [{ header: [2, 3, false] }],
        ["bold", "italic"],
        ["link"],
        [
            { list: "bullet" },
            { list: "ordered" },
            { indent: "-1" },
            { indent: "+1" }
        ]
    ];

    get isMidsummer() {
        return this.$store.getters.layoutOptions.siteName === "midsummer";
    }

    get pageContent() {
        return pagesStore.pageContent;
    }

    get isSuperUser() {
        return this.$store.getters.isSuperUser;
    }

    get hasError() {
        return pagesStore.hasError;
    }

    get notificationContent() {
        return this.hasError
            ? "There was an error saving the content."
            : "The content has been saved.";
    }

    get notificationClasses() {
        return this.hasError ? "my-2 is-danger" : "my-2 is-success";
    }

    @Watch("helpContent")
    updateContent() {
        // TODO: debounce this somehow, maybe? it should only be potentially slow for editors; for regular users this should only happen once
        this.headers = [];

        const container = document.createElement("div");
        container.innerHTML = this.helpContent;

        const allHeaders = container.querySelectorAll("h2");

        if (allHeaders.length) {
            allHeaders.forEach((header, index) => {
                const headerId = `header-${index}`;
                header.id = headerId;
                this.headers.push({
                    text: header.innerText,
                    id: headerId,
                    children: []
                });
            });

            const subheaders = container.querySelectorAll("h3");

            subheaders.forEach((subheader, index) => {
                const header = getPreviousElementBySelector(subheader, "h2");

                if (header) {
                    const headerObj = this.headers.find(
                        (item) => item.id === header.id
                    );

                    if (headerObj && headerObj.children) {
                        const subheaderId = `${headerObj.id}-sub-${index}`;
                        subheader.id = subheaderId;
                        headerObj.children.push({
                            text: subheader.innerText,
                            id: subheaderId
                        });
                    }
                }
            });
        }

        this.parsedContent = container.innerHTML;
    }

    mounted() {
        this.setupContent();
    }

    async setupContent() {
        await pagesStore.getContent("1");
        this.helpContent = this.pageContent;
        this.isLoading = false;
    }

    async saveContent() {
        try {
            await pagesStore.saveContent({
                id: "1",
                content: this.helpContent
            });
        } catch (error) {
            console.log(error);
        }

        this.showNotification = true;
    }

    disableNotification() {
        this.showNotification = false;
    }
}
